<template>
  <div>
    <AppBar :title="$t('report.totalSummary')"/>
    <v-card flat v-if="costs" class="rounded-xl pt-2 pb-4">
      <v-card-title>
        <v-chip v-if="filterStart || filterFinish" label>
          <span v-if="filterStart">{{ filterStart }}</span>
          <span v-if="filterStart && filterFinish" class="mx-2"> - </span>
          <span v-if="filterFinish">{{ filterFinish }}</span>
        </v-chip>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
      <v-row align="center">
          <v-col cols='2'>
            <v-menu
              v-model="menuDateFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start"
                  :label="$t('date.dateFrom')"
                  persistent-hint
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="start"
                no-title
                @input="menuDateFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols='2'>
            <v-menu
              v-model="menuDateTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="finish"
                  :label="$t('date.dateTo')"
                  persistent-hint
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="finish"
                no-title
                @input="menuDateTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
              <v-btn @click="getReport" color="secondary" depressed rounded>{{$t('report.show')}}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <div>
              <div class="d-flex justify-space-between my-2">
                <div>{{ $t('report.sumOfDeliveryCosts') }}:</div>
                <div class="font-weight-black">{{ costs.generalShippingCosts.toFixed(2) }} {{ getLocalCurrency }}</div>
              </div>
              <hr />
              <div class="d-flex justify-space-between my-2">
                <div>{{ $t('report.totalRecharges') }}:</div>
                <div class="font-weight-black">{{ totalCount.toFixed(2) }} {{ getLocalCurrency }}</div>
              </div>
              <hr />
              <div class="d-flex justify-space-between my-2">
                <div>{{ $t('report.total') }}:</div>
                <div class="font-weight-black">{{ (costs.generalShippingCosts+totalCount).toFixed(2) }} {{ getLocalCurrency }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{$t('name.title')}}</th>
              <th style="width: 200px" class="text-center">{{$t('report.numberOfCards')}}</th>
              <th style="width: 200px" class="text-center">{{$t('report.total')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cost, i) in costs.shoppingCenterCosts" :key="i">
              <td>{{ cost.name }}</td>
              <td style="width: 200px" class="text-center">{{ cost.cardsSold }}</td>
              <td style="width: 200px" class="text-center">{{ cost.cardsChargeAmount.toFixed(2) }} {{ getLocalCurrency }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'Report',
  data () {
    return {
      costs: null,
      start: null,
      finish: null,
      filterStart: null,
      filterFinish: null,
      menuDateFrom: false,
      menuDateTo: false
    }
  },
  computed: {
    totalCount () {
      return this.costs.shoppingCenterCosts.map(item => item.cardsChargeAmount).reduce((prev, curr) => prev + curr, 0)
    },
    getLocalCurrency () {
      return localStorage.getItem('currency')
    }
  },
  async created () {
    await this.getReport()
  },
  methods: {
    async getReport () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      try {
        const { data } = await Service.get(`/Orders/CostsByShoppingCenter?countryCode=${countryCode}&From=${this.start}&To=${this.finish}`)
        this.filterStart = this.start
        this.filterFinish = this.finish
        this.costs = data
      } catch (error) {}
      this.loading = false
    }
  }
}
</script>
